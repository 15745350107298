// extracted by mini-css-extract-plugin
export var arrowIcon = "vF_bK3";
export var arrowLink = "vF_bJ3";
export var buttonWrapper = "vF_bN3";
export var card = "vF_bL3";
export var featureCard = "vF_bM3";
export var heading = "vF_d3";
export var imgWrapper = "vF_bp3";
export var placeholder = "vF_bs3";
export var placeholderBtn = "vF_bH3";
export var placeholderHeader = "vF_bF3";
export var placeholderImg = "vF_bD3";
export var placeholderText = "vF_bG3";
export var white = "vF_bC3";